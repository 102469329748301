body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.React-logo {
  height: 50px;
  position: absolute;
  right: 0;
}

.App-logo {
  height: 25px;
  position: absolute;
  left: 0;
  padding: 10px;
}

.App-header {
  background-color: #222;
  height: 50px;
  color: white;
}

.mainLogo{
width: 450px;
}

#title{
text-align: center;
height: 100%;
}
